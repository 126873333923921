<template>
  <div>
    <kn-form-subtitle title="Agregar sub categoría de proveedor" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0"
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="subcategory.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Categoría de proveedor:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="subcategory.id_categoria_proveedor"
            dense
            outlined
            :rules="[rules.required]"
            :items="categories"
            item-value="id"
            item-text="dato"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de sub categorías de proveedor"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeSubcategories">
          <v-list-item :key="item.dato">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === subcategory.id"
                  class="mb-0 pb-0"
                  v-model="subcategory.dato"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.dato"
                />
                <v-select
                  v-if="toUpdate && item.id === subcategory.id"
                  class="mb-0 pb-0"
                  v-model="subcategory.id_categoria_proveedor"
                  dense
                  outlined
                  :items="categories"
                  item-value="id"
                  item-text="dato"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.categoria.dato"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === subcategory.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeSubcategories.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      subcategory: {
        id: 'NA', // No asignado
        dato: null,
        id_categoria_proveedor: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      subcategories: [],
      categories: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeSubcategories() {
      return this.subcategories.filter(subcategory => subcategory.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.subcategory)
    }
  },
  async created() {
    this.subcategory.id_autor = this.userData.id
    this.subcategory.id_institucion_educativa = this.institutionId
    this.subcategories = await this.fetchSupplierSubcategoriesByEI(this.institutionId)
    this.categories = await this.fetchSupplierCategoriesByEI(this.institutionId)
  },
  methods: {
    async fetchSupplierCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener categorias de proveedor por institucion educativa', error);
      }
    },
    async fetchSupplierSubcategoriesByEI(institutionId) {
      try {
        const response = await api.get(`personas/api/sub-categoria-proveedor-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener sub categorias de proveedor por institucion educativa', error);
      }
    },
    async createSupplierSubcategory() {
      try {
        const response = await api.post('/personas/crear-sub-categoria-proveedor', {
          nombre_sub_categoria_proveedor: this.subcategory.dato,
          id_categoria_proveedor: this.subcategory.id_categoria_proveedor,
          id_institucion_educativa: this.subcategory.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la subcategoria de proveedor.')
        }
      } catch (error) {
        console.error('Error al intentar crear la subcategoria de proveedor', error)
        this.errors.push('Error al intentar crear la subcategoria de proveedor')
      }
    },
    async updateSupplierSubcategory() {
      try {
        const response = await api.patch('/personas/update-sub-cetegoria-proveedor', {
          id: this.subcategory.id,
          sub_categoria_proveedor: this.subcategory.dato,
          id_categoria: this.subcategory.id_categoria_proveedor,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la subcategoria de proveedor')
        }
      } catch (error) {
        console.error('Error al intentar actualizar la subcategoria de proveedor', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteSupplierSubcategory(subcategoryId) {
      try {
        const response = await api.post('/personas/delete-sub-categoria-proveedor', {
          id_sub_categoria_proveedor: subcategoryId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la subcategoria de proveedor')
        }
      } catch (error) {
        console.error('Error al intentar eliminar la subcategoria de proveedor', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateSupplierSubcategory()
        this.toUpdate = false
      } else {
        await this.createSupplierSubcategory()
        this.clear()
      }
      this.subcategories = await this.fetchSupplierSubcategoriesByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria_proveedor: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria_proveedor: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.subcategory = {...item}
      this.subcategory.id_categoria_proveedor = item.categoria.id
      // console.log('Item a editar:', this.subcategory);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteSupplierSubcategory(itemId)
      this.subcategories = await this.fetchSupplierSubcategoriesByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>